class googleAnalytics {
    init(tagManager) {
        window.dataLayer = window.dataLayer || [];

        window.gtag = (...args) => {
            dataLayer.push(args);
        }

        // Zet de consent klaar voor analytics.
        if (localStorage.getItem('cookieConsent') === null) {
            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'analytics_storage': 'granted',
                'functionality_storage': 'granted',
                'security_storage': 'granted',
            });
        } else {
            gtag('consent', 'default', JSON.parse(localStorage.getItem('cookieConsent')));
        }

        // Laad de Google Tag Manager in. Met deze wordt ook direct de google analytics ingeladen.
        (function (window, document, scriptTag, dataLayerName, gtmId) {
            window[dataLayerName] = window[dataLayerName] || [];
            window[dataLayerName].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });

            let firstScript = document.getElementsByTagName(scriptTag)[0],
                newScript = document.createElement(scriptTag),
                dataLayerStr = dataLayerName !== 'dataLayer' ? '&l=' + dataLayerName : '';

            newScript.async = true;
            newScript.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmId + dataLayerStr;
            firstScript.parentNode.insertBefore(newScript, firstScript);

        })(window, document, 'script', 'dataLayer', tagManager);
    }
}

window.Analytics = new googleAnalytics();
